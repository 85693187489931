import React from "react";
import { NavBar } from "../components/NavBar";

const VolunteerPage = () => {
  return (
    <>
      <NavBar />
    </>
  );
};

export default VolunteerPage;
